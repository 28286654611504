// InviteeDataStep.js
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PlusOnesSection from './PlusOnesSection';

const InviteeDataStep = ({ inviteeData, isArabic, isSubmitting, setFieldValue }) => (
  <div>
    <h3 className="text-lg font-medium">
      {isArabic ? `مرحبا, ${inviteeData.name}!` : `Welcome, ${inviteeData.name}!`}
    </h3>

    {/* Partner Status */}
    {inviteeData.partnerName?<div className="mt-4">
      <label className="block text-sm font-medium text-gray-700">
        {isArabic ? 'الشريك' : 'Partner'}:
      </label>
        <div className="block">
            <span className="ml-2 font-semibold">{inviteeData.partnerName}</span>
        </div>
      <div className="flex flex-col ml-6">
        <label className={` flex items-center gap-2 mt-2`}>
            <Field type="radio" name="partnerStatus" value="1" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'سأحضر أنا وشريكي' : 'My partner and I will both attend'}</span>
            </label>
            <label className={` flex items-center gap-2 mt-2`}>
            <Field type="radio" name="partnerStatus" value="2" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'سأحضر بدون شريكي' : 'I will attend without my partner'}</span>
            </label>
            <label className={` flex items-center gap-2 mt-2`}>
            <Field type="radio" name="partnerStatus" value="3" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'سيحضر شريكي بدوني' : 'My Partner will attend alone'}</span>
            </label>
            <label className={` flex items-center gap-2 mt-2`}>
            <Field type="radio" name="partnerStatus" value="4" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'ليس بامكاننا الحضور' : 'Not attending'}</span>
        </label>
        {/* Additional partner status options */}
      </div>
      <ErrorMessage name="partnerStatus" component="div" className="text-red-500 text-sm" />
    </div>
    :<div className="mt-4">
              
      <div className="mt-2">
        
        <div className="flex flex-col ml-6">
          <label className={` flex items-center gap-2 mt-2`}>
            <Field type="radio" name="partnerStatus" value="2" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'سأحضر' : 'I will attend'}</span>
          </label>
          
          <label className={` flex items-center gap-2 mt-2`}>
            <Field type="radio" name="partnerStatus" value="4" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'ليس بامكاني الحضور' : 'Not attending'}</span>
          </label>
        </div>
      </div>
      <ErrorMessage name="partnerStatus" component="div" className="text-red-500 text-sm" />
    </div>}

    <PlusOnesSection inviteeData={inviteeData} isArabic={isArabic} setFieldValue={setFieldValue} />

    {/* Wish Message */}
    <div className="mt-4">
      <label htmlFor="message" className="block text-sm font-medium text-gray-700">
        {isArabic ? 'اترك أمنية (اختياري)' : 'Leave a Wish (Optional)'}:
      </label>
      <Field name="message" as="textarea" className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm" />
    </div>

    <div className="flex justify-between mt-4">
      <button type="submit" className="px-6 py-3 bg-text text-white rounded-md" disabled={isSubmitting}>
        {isSubmitting ? (isArabic ? 'جار التقديم...' : 'Submitting...') : (isArabic ? 'إرسال الرد' : 'Submit RSVP')}
      </button>
    </div>
  </div>
);

export default InviteeDataStep;
