import React from 'react';

const Blob = ({ flipped, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={flipped ? 'transform scale-x-[-1]' : ''}
      width="100%"
      zoomAndPan="magnify"
      viewBox="0 0 900 749.999995"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="5bf3fb8362">
          <path d="M 0 395 L 244 395 L 244 544 L 0 544 Z M 0 395" clipRule="nonzero" />
        </clipPath>
        <clipPath id="87e8b78c35">
          <path d="M 106.921875 361.675781 L 243.242188 467.378906 L 113.640625 634.519531 L -22.679688 528.816406 Z M 106.921875 361.675781" clipRule="nonzero" />
        </clipPath>
        <clipPath id="570e584166">
          <path d="M 133.515625 327.378906 L 269.835938 433.082031 L 113.582031 634.597656 L -22.738281 528.894531 Z M 133.515625 327.378906" clipRule="nonzero" />
        </clipPath>
      </defs>
      {/* <path
        className={color}
        d="M 525 750 C 318.289062 750 150 581.710938 150 375 C 150 168.289062 318.289062 0 525 0 C 731.710938 0 900 168.289062 900 375 C 900 581.710938 731.710938 750 525 750 Z M 525 5.542969 C 321.242188 5.542969 155.542969 171.242188 155.542969 375 C 155.542969 578.757812 321.242188 744.457031 525 744.457031 C 728.757812 744.457031 894.457031 578.757812 894.457031 375 C 894.457031 171.242188 728.757812 5.542969 525 5.542969 Z M 525 5.542969"
        fillOpacity="1"
        fillRule="nonzero"
      /> */}
      
      <g clipPath="url(#5bf3fb8362)">
        <g clipPath="url(#87e8b78c35)">
          <g clipPath="url(#570e584166)">
            <path
              className={color}
              d="M -3.527344 543.886719 C -1.777344 529.425781 1.839844 515.105469 7.386719 501.28125 C 12.765625 487.832031 19.992188 474.890625 29.058594 462.75 C 46.039062 440.21875 67.472656 422.964844 91.292969 411.53125 C 99.5 407.585938 107.996094 404.347656 116.6875 401.84375 C 125.414062 399.328125 134.332031 397.546875 143.320312 396.5 C 161.230469 394.433594 179.480469 395.234375 197.296875 398.976562 C 222.96875 404.375 247.699219 415.910156 269.410156 433.597656 C 247.125 417.160156 222.144531 407.089844 196.574219 402.769531 C 179.121094 399.828125 161.402344 399.617188 144.023438 402.011719 C 135.339844 403.203125 126.75 405.039062 118.316406 407.492188 C 109.921875 409.933594 101.695312 412.984375 93.695312 416.65625 C 70.328125 427.382812 48.960938 443.4375 31.84375 464.910156 C 22.492188 476.527344 14.933594 489.003906 9.09375 502.003906 C 3.023438 515.519531 -1.171875 529.597656 -3.527344 543.886719 Z M -3.527344 543.886719"
              fillOpacity="1"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Blob;
