// PhoneNumberStep.js
import React from 'react';
import { Field, ErrorMessage } from 'formik';

const PhoneNumberStep = ({ isArabic, isSubmitting }) => (
  <div>
    <label htmlFor="countryCode" className="block text-sm font-medium text-gray-700">
      {isArabic ? 'رمز الدولة' : 'Country Code'}:
    </label>
    <Field as="select" name="countryCode" className={`${isArabic && 'text-end'} mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm`}>
      <option dir='ltr' value="+966">{isArabic ? '+966 (السعودية)' : '+966 (Saudi Arabia)'}</option>
      <option dir='ltr' value="+1">{isArabic ? '+1 (الولايات المتحدة/كندا/دول أمريكا الشمالية الأخرى)' : '+1 (United States/Canada/other North American countries)'}</option>
      <option dir='ltr' value="+44">{isArabic ? '+44 (المملكة المتحدة)' : '+44 (UK)'}</option>
      <option dir='ltr' value="+90">{isArabic ? '+90 (تركيا)' : '+90 (Turkey)'}</option>
      <option dir='ltr' value="+962">{isArabic ? '+962 (الأردن)' : '+962 (Jordan)'}</option>
      <option dir='ltr' value="+971">{isArabic ? '+971 (الإمارات العربية المتحدة)' : '+971 (United Arab Emirates)'}</option>
      <option dir='ltr' value="+965">{isArabic ? '+965 (الكويت)' : '+965 (Kuwait)'}</option>
      <option dir='ltr' value="+20">{isArabic ? '+20 (مصر)' : '+20 (Egypt)'}</option>
      <option dir='ltr' value="+961">{isArabic ? '+961 (لبنان)' : '+961 (Lebanon)'}</option>
    </Field>

    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mt-4">
      {isArabic ? 'رقم الهاتف' : 'Phone Number'}:
    </label>
    <Field name="phoneNumber" type="text" className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm" />
    <ErrorMessage name="phoneNumber" component="div" className="text-red-500 text-sm" />

    <div className="flex justify-between mt-4">
      <button type="submit" className="px-6 py-3 bg-text text-white rounded-md" disabled={isSubmitting}>
        {isSubmitting ? (isArabic ? 'التحقق...' : 'Verifying...') : (isArabic ? 'تحقق من الهاتف' : 'Verify Phone')}
      </button>
    </div>
  </div>
);

export default PhoneNumberStep;
