import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { FaInstagram, FaPhone } from 'react-icons/fa';
import { FaFacebook, FaLocationArrow } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { FaGlobe } from "react-icons/fa";

const TwoSideSection = ({ isArabic, data, activeTabKey }) => {
  const [loading, setLoading] = useState(true); // Loading state
  const contentRef = useRef();

 

  useEffect(() => {
    if (data && data.vendors) {
      const firstTabKey = Object.keys(data.vendors)[0];
      setLoading(false); // Data is loaded, stop loading
    }
  }, [data, activeTabKey]);

  useEffect(() => {
    if (!loading && contentRef.current) {
      gsap.fromTo(contentRef.current, { opacity: 0 }, { opacity: 1, duration: 2 });
    }
  }, [activeTabKey, loading]);

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center py-10'>
        <div className='rounded-lg p-6 w-full text-center'>
          <h2 className='text-2xl md:text-3xl font-bold text-text mb-4'>
            {isArabic ? "جارٍ تحميل البيانات..." : "Loading data..."}
          </h2>
        </div>
      </div>
    );
  }

  if (!data || !data.vendors) {
    return (
      <div className='w-full flex justify-center items-center py-10'>
        <div className='rounded-lg p-6 w-full text-center'>
          <h2 className='text-2xl md:text-3xl font-bold text-text mb-4'>
            {isArabic ? "بيانات غير متاحة" : "Data Not Available"}
          </h2>
          <p className='text-lg md:text-2xl text-text font-medium my-4'>
            {isArabic ? "نأسف، لم يتم العثور على بيانات الموردين." : "Sorry, no vendor data found."}
          </p>
        </div>
      </div>
    );
  }

  const tabs = Object.keys(data.vendors);
  const selectedTabData = data.vendors[activeTabKey]; // Get the vendor data for the active tab

  // Handle undefined selectedTabData
  if (!selectedTabData) {
    return (
      <div className='w-full flex justify-center items-center py-10'>
        <div className='rounded-lg p-6 w-full text-center'>
          <h2 className='text-2xl md:text-3xl font-bold text-text mb-4'>
            {isArabic ? "لا توجد بيانات لهذا المورد" : "No data for this vendor"}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full h-full relative z-20 min-h-[90vh] pt-20 md:pt-10 pb-40 grid grid-cols-1 md:grid-cols-3 bg-primary bg-opacity-70'>
      {/* Tabs Section for Large Screens */}
      {/* <div className='w-full h-full md:flex flex-col items-center hidden'>
        <div className="text-white text-center mt-4 flex flex-col justify-evenly items-center h-full min-h-[40vh]">
          {tabs.map((tabKey) => (
            <div
              key={tabKey}
              onClick={() => handleTabClick(tabKey)}
              className={`cursor-pointer py-2 ${activeTabKey === tabKey ? 'font-bold underline' : ''}`}
            >
              {isArabic ? data.vendors[tabKey].nameArabic : data.vendors[tabKey].name}
            </div>
          ))}
        </div>
      </div> */}

      {/* Tabs Section for Small Screens */}
      {/* <div className="w-full h-auto pt-20 block md:hidden overflow-x-scroll scrollbar-hide bg-secondary">
        <div className="flex justify-start space-x-4 px-4 py-2">
          {tabs.map((tabKey) => (
            <div
              key={tabKey}
              onClick={() => handleTabClick(tabKey)}
              className={`cursor-pointer whitespace-nowrap py-2 px-4 ${activeTabKey === tabKey ? 'font-bold underline' : ''}`}
            >
              {isArabic ? data.vendors[tabKey].nameArabic : data.vendors[tabKey].name}
            </div>
          ))}
        </div>
      </div> */}
      
      {/* Right Section showing dynamic content */}
      <div ref={contentRef} className='w-full h-full min-h-[80vh] col-span-3 flex flex-col justify-start p-8'>
      {selectedTabData.name === "Gift Registry" || selectedTabData.nameArabic === "سجل الهدايا" ?
        <h2 dir={isArabic?'rtl':'ltr'} className={`text-text text-center text-lg font-bold mb-4 mt-8  w-full md:w-1/2 self-center leading-loose`}>
          {isArabic ? 'نحن ممتنون للغاية لمشاركتكم معنا في حفل زفافنا، فوجودكم هو بالفعل أعظم هدية يمكن أن نحصل عليها. ولمن يرغب من الأهل والأصدقاء في تقديم هدية، قمنا بتخصيص حساب لتسهيل الأمر عليكم.' 
          : 'We are deeply grateful for your presence at our wedding, as it is truly the most precious gift to us. Your company is what makes our day special. For friends and family who desire to give a gift, we have established a dedicated account for your convenience.'}
        </h2>
        :<h2 className={`text-text text-center text-xl md:text-4xl font-bold mb-10 ${isArabic?'noto':'cinzel-decorative-regular'}`}>
          {isArabic ? selectedTabData.nameArabic : selectedTabData.name}
        </h2>}
        
        {/* Check if the selected tab is the Gift Registry */}
        {selectedTabData.name === "Gift Registry" || selectedTabData.nameArabic === "سجل الهدايا" ? (
          <div className={`text-text text-center grid grid-cols-1 gap-4`}>
            {Object.entries(selectedTabData.vendorList).map(([vendorId, item], index) => (
              <div key={index} className="relative z-50 shadow-lg rounded-lg border border-primary p-4 flex flex-col justify-between">
                <h3 className="text-lg font-bold mb-2 ">
                  {isArabic ? item.bankNameArabic : item.bankName}
                </h3>
                <p className="text-sm">
                  <strong>{isArabic ? "اسم المستفيد" : "Account Name"}: </strong>
                  {isArabic ? item.accountNameArabic : item.accountName}
                </p>
                <p className="text-sm">
                  <strong>IBAN: </strong>{item.IBAN}
                </p>
                <p dir={isArabic?'rtl':'ltr'} className="text-sm">
                  <strong>{isArabic ? "رقم الحساب" : "Account Number"}: </strong>{item.accountNumber}
                </p>
                <p className="text-sm">
                  <strong>SWIFT: </strong>{item.SWIFT}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className={`text-text ${isArabic && 'text-end'} grid grid-cols-2 sm:grid-cols-4 gap-4`}>
            {selectedTabData?.vendorList ? (
              Object.entries(selectedTabData.vendorList).map(([vendorId, item], index) => (
                <div key={index} className={`relative mb-4 shadow-lg rounded-lg border border-primary flex flex-col justify-between ${vendorId==='5' && 'col-span-2 sm:col-span-3'}`}>
                  <div className={`p-4  flex flex-col ${vendorId === '5' ? 'w-full items-center' : 'md:flex-row md:text-start md:items-center'} items-center text-center`}>
                    <img
                      src={`https://www.mohammedandlama.com/vendors/${activeTabKey}/${vendorId}.jpg`}
                      alt={`${item.name}'s avatar`}
                      className="w-16 h-16 rounded-full mx-4"
                    />
                    <div>
                      <strong className="text-lg text">{isArabic ? item.nameArabic : item.name}</strong>
                    </div>
                    
                    {item.reserve && (
                      <a
                        href={`${item.reserve}`}
                        target="_blank"
                        className="text-text flex items-center text-sm border-[1px] p-2 m-2 rounded-lg border-primary"
                      >
                        {isArabic ? 'انقر هنا للحصول على سعر خاص لضيوفنا' : 'Click here for special rate to our guests'}
                      </a>
                    )}
                  </div>
                  <div
                    className={`border-t border-primary flex ${
                      [item.socialMedia, item.mobileNumber, item.googleMaps].filter(Boolean).length > 1
                        ? 'justify-evenly'
                        : 'justify-center'
                    } items-end p-2`}
                     >   {item.socialMedia && (
                      <a
                        href={item.socialMedia}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 flex items-center text-sm"
                      >
                        {item.socialMedia?.includes('facebook.com') ? (
                          <FaFacebook className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                        ) : item.socialMedia?.includes('instagram.com') ? (
                          <FaInstagram className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                        ) : item.socialMedia?.includes('/') ? (
                          <FaGlobe className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                        ) : null}
                        </a>
                    )}
                    
                    {item.mobileNumber && (
                      <>
                        <div className={`${item.socialMedia?'border-l':''} border-primary h-full`}></div>
                        <a
                          href={`tel:${item.mobileNumber}`}
                          className="text-blue-500 flex items-center text-sm"
                        >
                          <FaPhone className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                        </a>
                      </>
                    )}
                    {item.googleMaps && (
                      <>
                        <div className={`${item.mobileNumber?'border-l':''} border-primary h-full`}></div>
                        <a
                          href={item.googleMaps}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 flex items-center text-sm"
                        >
                          <FaLocationArrow className="w-3 h-3 md:w-5 md:h-5 mr-2" />
                        </a>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-600">{isArabic ? "لا توجد سجلات" : "No records found"}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoSideSection;
