// Rsvp.js
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PhoneNumberStep from './PhoneNumberStep';
import InviteeDataStep from './InviteeDataStep';

const Rsvp = ({ isArabic }) => {
  const [inviteeData, setInviteeData] = useState(null);

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^\d+$/, 'Phone number must be digits only'),
  });

  const initialValues = {
    countryCode: '+966',
    phoneNumber: '',
    partnerStatus: '',
    plusOnes: {},
    message: '',
  };

  const verifyPhoneNumber = async (values, { setSubmitting, setFieldValue }) => {
    try {
      let phoneNumber = values.phoneNumber;
      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.slice(1);
      }

      const response = await fetch('https://mohammedandlama.com/verify_phone', {
        method: 'POST',
        headers: {
          Authorization: 'ZPJTjkUuhg%',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mobileNumber: values.countryCode + phoneNumber,
        }),
      });

      const data = await response.json();

      if (data.clientKey) {
        setInviteeData({
          clientKey: data.clientKey,
          name: data.name,
          partnerName: data.partnerName,
          status: data.status,
          wishNote: data.wishNote,
          plusOnes: data.plusOnes || {},
        });

        setFieldValue('partnerStatus', data.status.toString());
        setFieldValue('message', data.wishNote);

        Object.entries(data.plusOnes || {}).forEach(([key, plusOne]) => {
          setFieldValue(`plusOnes.${key}.name`, plusOne.name);
          setFieldValue(`plusOnes.${key}.status`, plusOne.status.toString());
        });
      } else {
        alert('Phone number not found in the invite list');
      }

      setSubmitting(false);
    } catch (error) {
      console.error('Error verifying invitee:', error);
      setSubmitting(false);
    }
  };

  const submitRsvp = async (values, { setSubmitting }) => {
    try {
      const updatedPlusOnes = Object.keys(values.plusOnes).reduce((acc, key) => {
        acc[key] = {
          name: values.plusOnes[key].name || inviteeData.plusOnes[key]?.name,
          status: values.plusOnes[key].status,
        };
        return acc;
      }, {});

      const updatedData = {
        clientKey: inviteeData.clientKey,
        status: values.partnerStatus,
        plusOnes: updatedPlusOnes,
        wishNote: values.message,
      };

      const response = await fetch('https://mohammedandlama.com/updater', {
        method: 'POST',
        headers: {
          Authorization: 'ZPJTjkUuhg%',
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        alert('RSVP submitted successfully!');
      } else {
        alert('Error submitting RSVP.');
      }

      setSubmitting(false);
    } catch (error) {
      console.error('Error submitting RSVP:', error);
      setSubmitting(false);
    }
  };

  return (
    <div dir={isArabic ? 'rtl' : 'ltr'} className="rsvp-form w-full max-w-lg mx-auto my-10 p-6 bg-primary bg-opacity-40 rounded-lg shadow-md">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={!inviteeData ? verifyPhoneNumber : submitRsvp}
        validateOnChange={false}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            {!inviteeData ? (
              <PhoneNumberStep isArabic={isArabic} isSubmitting={isSubmitting} />
            ) : (
              <InviteeDataStep
                inviteeData={inviteeData}
                isArabic={isArabic}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Rsvp;
