import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiVolume2, FiVolumeX } from "react-icons/fi"; // Importing icons for volume control

const Navbar = ({ isArabic, setIsArabic, isMuted, toggleMute, setActiveTabKey }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();

  const handleRSVPClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    toggleMenu(); // Toggle the menu if necessary

    // Navigate to home and then scroll to the #rsvp section
    navigate('/'); // Navigate to home page
    setTimeout(() => {
      const rsvpElement = document.getElementById('rsvp');
      if (rsvpElement) {
        rsvpElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Adding a slight delay to ensure the navigation completes
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleService = (item) => {
    setMenuOpen(!menuOpen);
    setActiveTabKey(item)
  };
  const toggleArabic = () => {
    setMenuOpen(!menuOpen);
    setIsArabic(prev=>!prev)
  };

  return (
    <header className="w-full z-[99999] fixed top-0 left-0 ">
      <nav className="w-full flex items-center justify-between p-4 lg:px-8">
        {/* Mobile Hamburger Button */}
        <div className="flex">
          <button
            type="button"
            className="inline-flex items-center justify-center p-2.5 text-primarydark"
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            <span className="p-2 self-center justify-self-center">{isArabic?'القائمة':'Menu'}</span>
            
          </button>
        </div>

        {/* Mute/Unmute Icon on the opposite side */}
        <div className="flex">
          <button
            type="button"
            className="inline-flex items-center justify-center p-2.5 text-primarydark"
            onClick={toggleMute} // Toggle mute/unmute when clicked
          >
          {/* <span className="p-2 self-center justify-self-center">{isMuted ? "Unmute" : "Mute"}</span> */}
            {isMuted ? (
              <FiVolumeX className="h-6 w-6" aria-hidden="true" /> // Muted icon
            ) : (
              <FiVolume2 className="h-6 w-6" aria-hidden="true" /> // Unmuted icon
            )}
            <span className="sr-only">{isMuted ? "Unmute audio" : "Mute audio"}</span>
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 z-50 w-1/2 md:w-1/4 bg-primary shadow-2xl transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        {/* Close Button */}
        <button
          type="button"
          className="absolute top-4 right-4 p-2.5 text-primarydark"
          onClick={toggleMenu}
        >
          <span className="sr-only">Close menu</span>
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Menu Content */}
        <div className={`p-6 ${isArabic ? "text-end" : "text-start"}`}>
          <div className="mt-6 flex flex-col items-start w-full space-y-4">
          <Link
            to={`/`}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={toggleMenu}
          >
            {isArabic ? 'الرئيسية' : 'Home'}
          </Link>
          <a
            href="#rsvp"
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={handleRSVPClick}
          >
            {isArabic?'تأكيد الحضور':'RSVP'}
          </a>
          <Link
            to={`/timeline`}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={toggleMenu}
          >
            {isArabic ? 'الجدول الزمني' : 'Timeline'}
          </Link>
          
          <Link
            to="/services"
            state={{ mactiveTabKey: '2' }}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={()=>toggleService('2')}
          >
            {isArabic ? 'مصففي الشعر' : 'Hair Stylists'}
          </Link>
          <Link
            to="/services"
            state={{ mactiveTabKey: '1' }}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={()=>toggleService('1')}
          >
            {isArabic ? 'فنانين المكياج' : 'Make-Up Artists'}
          </Link>
          <Link
            to="/services"
            state={{ mactiveTabKey: '100' }}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={()=>toggleService('100')}
          >
            {isArabic ? 'سجل الهدايا' : 'Gift Registry'}
          </Link>
          <Link
            to="/services"
            state={{ mactiveTabKey: '7' }}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={()=>toggleService('7')}
          >
            {isArabic ? 'الإقامة' : 'Accommodation'}
          </Link>
          
          <Link
            to="/services"
            state={{ mactiveTabKey: '4' }}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={()=>toggleService('4')}
          >
            {isArabic ? 'أماكن للزيارة' : 'Places To Go'}
          </Link>
          <Link
            to="/services"
            state={{ mactiveTabKey: '3' }}
            className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md"
            onClick={()=>toggleService('3')}
          >
            {isArabic ? 'النقل' : 'Transportation'}
          </Link>
          
          <div className="block w-full text-base font-semibold text-text hover:bg-secondary p-2 rounded-md cursor-pointer" onClick={toggleArabic}>{isArabic?'English':'عربي'}</div>

            
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
