import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Blob from './blob';
import bg1 from '../assets/bg1.jpg';
import cou1 from '../assets/wed1.png'
import cou2 from '../assets/wed2.png'
import cou3 from '../assets/wed3.png'
import cou4 from '../assets/wed4.png'
import cou5 from '../assets/wed5.png'

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

const StorySection = ({ title, time, content, sectionRef, isArabic }) => {
  return (
    <div 
      dir={isArabic?'rtl':'ltr'}
      ref={sectionRef} 
      className={`flex flex-col justify-center h-full my-16 md:my-44 px-4 text-center text-text `}
    >
      <h2 className='text-xl md:text-3xl text-text'>{title}</h2>
      <p className='text-xs md:text-sm'>{time}</p>
      {/* <p className='pt-2 md:pt-10 text-[8px] md:text-lg'>{content}</p> */}
    </div>
  );
};

const BlobImageSection = ({ image, flipped, blobRef }) => {
  return (
    <div 
      ref={blobRef} 
      id='blob' 
      className='relative'
    >
      <Blob flipped={flipped ? true : false} color={'fill-primary'} />
      <img
        className={`absolute border-2 border-primary p-2 rounded-full top-0 ${flipped ? 'left-[3.2%]' : 'right-[3.2%]'} h-[100%]`}
        src={image}
        alt="Blob"
      />
    </div>
  );
};
const LoveStory = ({ scrollerRef, isArabic }) => {
  const sectionRefs = useRef([]);
  const blobRefs = useRef([]);
  const blobleftRefs = useRef([]);

  useEffect(() => {
    sectionRefs.current.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, scale: 0.8, x: 50 },
        {
          opacity: 1,
          scale: 1,
          x: 0,
          duration: 2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: section,
            // scroller: scrollerRef.current,
            start: 'top 70%',
            toggleActions: 'play none none none',
          },
        }
      );
    });

    blobRefs.current.forEach((blob, index) => {
      gsap.fromTo(
        blob,
        { opacity: 0, y: 50, x: -50 },
        {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: blob,
            // scroller: scrollerRef.current,
            start: 'top 70%',
            toggleActions: 'play none none none',
          },
        }
      );
    });
    blobleftRefs.current.forEach((blob, index) => {
      gsap.fromTo(
        blob,
        { opacity: 0, y: 50, x: 50 },
        {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 2,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: blob,
            // scroller: scrollerRef.current,
            start: 'top 70%',
            toggleActions: 'play none none none',
          },
        }
      );
    });
  }, []);

  useEffect(() => {
    const rightHalf = document.querySelector(".right-half");
    const rightHalft = document.querySelector(".right-halft");

    gsap.fromTo(
      rightHalf,
      { scale: 0.3 },
      {
        scale: 1, // Adjust zoom level
        duration: 3,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: rightHalf,
          // scroller: scrollerRef.current,
          start: 'top bottom',
          end: 'bottom 40%',
          markers:false,
          scrub: true,
          toggleActions: 'play none none none',
        },
      }
    );
  }, []);

  return (
    <div className='z-30 w-full mb-20 pb-20'>
      <div className={`flex justify-center my-20 text-center font-bold text-3xl md:text-4xl ${isArabic?'noto':'cinzel-decorative-regular'}`}>
        {isArabic ? " يوم الزفاف" : "Wedding Day"}
      </div>

      <div className='grid grid-cols-2 grid-rows-1 w-[98%] md:w-[80%] mx-auto'>
        {/* Left Column */}
        <div className='border-r-2 border-primary flex flex-col justify-between text-text'>
        <StorySection 
            isArabic={isArabic}
            sectionRef={(el) => (sectionRefs.current[0] = el)}
            title={isArabic ? "الاستقبال" : "Reception"}
            time={isArabic ? "الساعة 7:00 مساءً" : "7:00 PM"}
            content={isArabic ? 
              "تحضير العروس والعريس لليوم الكبير. الفريق يتأكد من أن كل شيء جاهز." : 
              "Bride and groom get ready for the big day. The team ensures everything is set for the wedding."} 
          />
          <BlobImageSection 
            blobRef={(el) => (blobleftRefs.current[0] = el)}
            flipped 
            image={cou2} 
          />
          <StorySection 
            isArabic={isArabic}
            sectionRef={(el) => (sectionRefs.current[1] = el)}
            title={isArabic ? "زفة العروس" : "Bride's Entrance"}
            time={isArabic ? "الساعة 08:15 مساءً" : "08:15 PM"}
            content={isArabic ? 
              "الوصول إلى مكان الزفاف والالتقاء بالضيوف. الجميع يستعد لبدء الحفل." : 
              "Arriving at the wedding venue and meeting the guests. Everyone is preparing for the ceremony to begin."} 
          />
          <BlobImageSection 
            blobRef={(el) => (blobleftRefs.current[1] = el)}
            flipped 
            image={cou4} 
          />
          <StorySection 
            isArabic={isArabic}
            sectionRef={(el) => (sectionRefs.current[1] = el)}
            title={isArabic ? "الزفة الثانية" : "Second Entrance"}
            time={isArabic ? "الساعة 12:00 صباحاً" : "12:00 AM"}
            content={isArabic ? 
              "الوصول إلى مكان الزفاف والالتقاء بالضيوف. الجميع يستعد لبدء الحفل." : 
              "Arriving at the wedding venue and meeting the guests. Everyone is preparing for the ceremony to begin."} 
          />
        </div>

        {/* Right Column */}
        <div className='flex flex-col justify-between text-text '>
        <BlobImageSection 
            blobRef={(el) => (blobRefs.current[2] = el)}
            image={cou1} 
          />
          <StorySection 
            isArabic={isArabic}
            sectionRef={(el) => (sectionRefs.current[3] = el)}
            title={isArabic ? "زفة العريس" : "Groom's Entrance"}
            time={isArabic ? "الساعة 08:00 مساءً" : "08:00 PM"}
            content={isArabic ? 
              "بدء الحفل، حيث يتبادل العروس والعريس عهود الزواج أمام الأهل والأصدقاء." : 
              "The ceremony begins, with the bride and groom exchanging vows in front of family and friends."} 
          />
          <BlobImageSection 
            blobRef={(el) => (blobRefs.current[3] = el)}
            image={cou3} 
          />
          <StorySection 
            isArabic={isArabic}
            sectionRef={(el) => (sectionRefs.current[4] = el)}
            title={isArabic ? "العشاء" : "Dinner"}
            time={isArabic ? "الساعة 10:45 مساءً" : "10:45 PM"}
            content={isArabic ? 
              "حان الوقت للاحتفال والرقص مع الأهل والأصدقاء بعد الحفل." : 
              "Time to celebrate and dance with friends and family after the ceremony."} 
          />
          <BlobImageSection 
            blobRef={(el) => (blobRefs.current[3] = el)}
            image={cou5} 
          />
          
        </div>
      </div>
    </div>
  );
};

export default LoveStory;

