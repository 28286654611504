// PlusOnesSection.js
import React from 'react';
import { Field } from 'formik';

const PlusOnesSection = ({ inviteeData, isArabic, setFieldValue }) => (
  <div className="mt-4">
    {inviteeData.plusOnes[0] &&<label className="block text-sm font-medium text-gray-700">
      {isArabic ? 'الأشخاص الإضافيون' : 'Plus Ones'}:
    </label>}
    {Object.entries(inviteeData.plusOnes).map(([key, plusOne], index) => (
      <div key={index} className="mt-4">
        <div className="block">
          {plusOne.name ? (
            <span className="ml-2 font-semibold">{plusOne.name}</span>
          ) : (
            <Field
              name={`plusOnes.${key}.name`}
              type="text"
              placeholder={isArabic ? 'أدخل الاسم' : 'Enter name'}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          )}
        </div>
        <div className="flex flex-col ml-6">
          <label className="flex items-center gap-2 mt-2">
            <Field type="radio" name={`plusOnes.${key}.status`} value="1" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'قادم' : 'Coming'}</span>
          </label>
          <label className="flex items-center gap-2 mt-2">
            <Field type="radio" name={`plusOnes.${key}.status`} value="2" className="form-radio text-blue-600" />
            <span className="ml-2">{isArabic ? 'لن يأتي' : 'Not Coming'}</span>
          </label>
        </div>
      </div>
    ))}
  </div>
);

export default PlusOnesSection;
