import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import logo from '../assets/cleanlogo.png';
import logoar from '../assets/test.png';
import { BiCalendar, BiCalendarEvent } from 'react-icons/bi';
import { FaLocationDot } from 'react-icons/fa6';

const Header = ({ isArabic }) => {
  const sizeOne = `text-2xl md:text-6xl my-10 md:my-20 ${isArabic?'noto':'cinzel-decorative-regular'}`;
  const sizeTwo = `text-xl md:text-4xl my-10  ${isArabic?'noto':'cinzel-decorative-regular'}`;
  const sizeThree = `text-center my-5 px-2 ${isArabic?'noto md:text-5xl sm:text-lg text-base' :'cinzel-decorative-regular md:text-3xl sm:text-lg text-base'}`;

  
  const textRefs = useRef([]);

  const calculateTimeLeft = () => {
    const targetDate = new Date('2025-01-24T19:00:00+02:00');
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const flowerRef = useRef(null); // Reference for the flower image
  const secRef = useRef(null);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    // GSAP animation for staggered left-to-right fade-in
    gsap.fromTo(
      textRefs.current,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 1.5,
        ease: 'power3.out',
        delay: 0.2,
        stagger: 0.2,
      }
    );
  }, []);

  return (
    <div className='flex flex-col w-full items-center  mb-32 z-30 '>
      {isArabic?<img src={logo} className='w-[20%] h-auto mx-auto my-10'/>:
      <img src={logoar} className='w-[20%] h-auto mx-auto my-10'/>}
      {/* <div ref={(el) => (textRefs.current[0] = el)} className={`${sizeOne}`}>
        {isArabic ? "بارك الله لهما و بارك عليهما و جمع بينهما في خير" : "May Allah bless them and bring them together in goodness"}
      </div>
      <div ref={(el) => (textRefs.current[1] = el)} className={`${sizeOne}`}>
        {isArabic ? "يتشرف" : "We are honored"}
      </div>
      <div className={`flex ${isArabic?'flex-row':'flex-row-reverse'} justify-evenly items-center w-full `}>
        <div className={`${sizeTwo} flex flex-col items-center`}>
          <div ref={(el) => (textRefs.current[2] = el)}>
            {isArabic ? "و السيدة/ سلوی صالح اشقر" : "Mrs. Salwa Saleh Ashqar"}
          </div>
          <div ref={(el) => (textRefs.current[3] = el)}>
            {isArabic ? "حرم المرحوم عمار عبدالعزيز الرفاعي" : "Wife of the late Ammar Abdulaziz Al-Rifaai"}
          </div>
        </div>
        <div className={`${sizeTwo} flex flex-col items-center`}>
          <div ref={(el) => (textRefs.current[4] = el)}>
            {isArabic ? "السيد/أسامة مختار راغب" : "Mr. Osama Mokhtar Ragheb"}
          </div>
          <div ref={(el) => (textRefs.current[5] = el)}>
            {isArabic ? "والسيدة/ ريم أسعد مسعود" : "and Mrs. Reem Asaad Masoud"}
          </div>
        </div>
      </div> */}
      <div ref={(el) => (textRefs.current[0] = el)} className={`${sizeOne}`}>
        {isArabic ? " حفل زفاف" : "wedding celebration"}
      </div>
      <div className={`flex ${isArabic ? 'flex-row' : 'flex-row-reverse'} justify-center items-center w-full`}>
        <div className={`flex flex-col items-center w-full`}>
          <div ref={(el) => (textRefs.current[2] = el)} className={`${sizeThree}`}>
            {isArabic ? (
              <>
                محمد <br /> عمار الرفاعي
              </>
            ) : (
              <>
                Mohammed <br /> Ammar AlRefae
              </>
            )}
          </div>
        </div>
  
        <div className={`flex flex-col items-center mx-4`}>
          <div ref={(el) => (textRefs.current[3] = el)} className={`${sizeTwo} `}>
            {isArabic ? "على " : "&"}
          </div>
        </div>
  
        <div className={`flex flex-col items-center w-full`}>
          {isArabic ? (
            <div ref={(el) => (textRefs.current[4] = el)} className={`${sizeThree}`}>
              لاما <br /> اسامه راغب
            </div>
          ) : (
            <div ref={(el) => (textRefs.current[4] = el)} className={`${sizeThree}`}>
              Lama <br /> Osama Rag<span className='tracking-[0.75px]'>h</span>eb
            </div>
          )}
        </div>
      </div>


      <div className="grid grid-cols-2 py-10  z-30">
        <div ref={(el) => (textRefs.current[5] = el)} className="pr-10 flex flex-col border-secondary border-r-[1px] text-base md:text-2xl justify-between items-center ga">
          <div className='my-4'>{isArabic ? "مدينة القاهرة، مصر" : "Cairo, Egypt"}</div>
          <div className='mb-4'>{isArabic ? "فندق سانت ريجيس " : "The St. Regis Hotel"}</div>
          {/* <div>{isArabic ? "القاهرة" : "Cairo"}</div> */}
          <a href="https://maps.app.goo.gl/2gz5UkP29nzwRDLJA" target="_blank" rel="noopener noreferrer" className='flex flex-row border-[1px] border-[#634d24] rounded-lg p-2 items-center gap-1 text-xs'>
            <FaLocationDot className='w-5 h-5'/>
            <div className=' text-xs md:text-xl tracking-[0.05px] '>{isArabic ? "الذهاب للموقع" : "Go To Location"}</div>
          </a>
        </div>
        <div ref={(el) => (textRefs.current[6] = el)} className="pl-10 flex flex-col text-base md:text-2xl justify-between items-center ga">
          <div className='my-4'>{isArabic ? "يوم الجمعة الموافق" : "Friday"}</div>
          <div dir={isArabic?'rtl':'ltr'} className='mb-4'>{isArabic ? <div className='flex gap-1'>٢٤ يناير، ٢٠٢٥</div> : "January 24th 2025"}</div>
          <div></div>
          <a href="https://mohammedandlama.com/assets/mohammedandlama.ics" download="mohammedandlama.ics" className='flex flex-row border-[1px] border-[#634d24] rounded-lg p-2 items-center gap-1 text-xs'>
            <BiCalendarEvent className='w-5 h-5'/>
            <div className=' text-xs md:text-xl'>{isArabic ? "إضافة للتقويم" : "Add to calendar"}</div>
          </a>
        </div>
      </div>
      {/* Countdown Timer */}
      <div className={`flex  justify-evenly w-full items-center gap-4 text-center text-4xl md:text-6xl font-bold  mt-10`}>
        <div ref={(el) => (textRefs.current[7] = el)} className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.days || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "أيام" : "Days"}</span>
        </div>
        <div ref={(el) => (textRefs.current[11]=el)} className='text-4xl md:text-6xl'>:</div>
        <div ref={(el) => (textRefs.current[8] = el)} className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.hours || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "ساعات" : "Hours"}</span>
        </div>
        <div ref={(el) => (textRefs.current[12]=el)}  className='text-4xl md:text-6xl'>:</div>
        <div ref={(el) => (textRefs.current[9] = el)} className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.minutes || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "دقائق" : "Minutes"}</span>
        </div>
        <div ref={(el) => (textRefs.current[13]=el)} className='text-4xl md:text-6xl'>:</div>
        <div ref={(el) => (textRefs.current[10] = el)} className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.seconds || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "ثواني" : "Seconds"}</span>
        </div>
      </div>

    </div>
  );
}

export default Header;
