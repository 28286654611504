import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import bgImage from './assets/bg1.jpg';
import Landing from "./pages/landing";
import { MemoryRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import flowerimgmoving from './assets/2.svg';
import cloudImg from './assets/Tclouds.png'

function App() {
  const scrollContainerRef = useRef(null); // Create a ref for the scroll container
  const flower1Ref = useRef(null); // Ref for the first flower image
  const flower2Ref = useRef(null); // Ref for the second flower image
  const cloudRef = useRef(null);
  const cloudRef2 = useRef(null);
  

  useEffect(() => {
    // Create the entrance animation
    gsap.timeline()
      .fromTo(
        flower1Ref.current, 
        { x: '100vw', opacity: 0, rotation: 10, }, // Start off the screen (right)
        { x: '0vw', opacity: 0.5, duration: 3,rotation: 0, ease: 'power2.out' } // Animate to the final position
      )
      .fromTo(
        flower2Ref.current, 
        { x: '-100vw', opacity: 0,rotation: 10, }, // Start off the screen (left)
        { x: '0vw', opacity: 0.5, duration: 2, rotation: 0, ease: 'power2.out' }, 
        "<" // Start the second animation at the same time as the first
      );

    // Add swinging animations with fixed bottom
    gsap.to(flower1Ref.current, {
      rotation: 5,
      transformOrigin: "bottom center", // Fixes the bottom of the image
      yoyo: true,
      repeat: -1,
      duration: 5,
      ease: 'power1.inOut'
    });

    gsap.to(flower2Ref.current, {
      rotation: -5,
      transformOrigin: "bottom center", // Fixes the bottom of the image
      yoyo: true,
      repeat: -1,
      duration: 9,
      ease: 'power1.inOut'
    });
    // Add a slow left-right movement for the cloud
    gsap.fromTo(cloudRef.current, {
      x: -400, // Move 50 pixels to the right
      yoyo: true,
      scale:1.5,
      
    },{
      x: 400, // Move 50 pixels to the right
      yoyo: true,
      repeat: -1,
      duration: 20, // Slow movement (5 seconds)
      
    });
    // Add a slow left-right movement for the cloud
    gsap.fromTo(cloudRef2.current, {
      x: -400, // Move 50 pixels to the right
      yoyo: true,
      scale:3,
      
    },{
      x: 400, // Move 50 pixels to the right
      yoyo: true,
      repeat: -1,
      duration: 20, // Slow movement (5 seconds)
      
    });

    
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="relative h-[100%] overscroll-none w-screen overflow-hidden">
        <div
          className="fixed top-0 left-0 w-full h-full"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: -1, // Ensure background stays behind content
          }}
        />
        <div
          ref={scrollContainerRef}
          className="relative h-[100%] overscroll-none w-full overflow-y-auto overflow-x-hidden"
        >
        

        {/* SVG Animations */}
        <img ref={flower1Ref} src={flowerimgmoving} alt="flower1" id="svg1" className="fixed z-[20] w-6/12 md:w-3/12 h-auto   right-0 md:-right-5 top-0"/>
        {/* <img ref={flower2Ref} src={flowerimgmoving} alt="flower1" id="svg1" className="z-50 transform scale-x-[-1] w-6/12 md:w-3/12 h-auto opacity-50 absolute left-0 md:-left-5 top-0"/> */}
        {/* <img ref={flower2Ref} src={flowerimgmoving} alt="flower2" id="svg2" className="z-50 w-6/12 transform scale-x-[-1] rotate-180 md:w-2/6 h-auto opacity-50 absolute left-0 md:-left-5 bottom-56 lg:bottom-[20%]"/> */}
        
        <img ref={cloudRef} src={cloudImg}  className='fixed  hidden md:block md:h-[100vh] pt-[20%] md:pt-0  w-auto'/>
        <img ref={cloudRef2} src={cloudImg}  className='fixed md:hidden h-[50vh] md:h-[100vh] pt-[20%] md:pt-0  w-auto'/>
        {/* <div ref={cloudRef} id="cloud-circle" className=''></div>
        <svg width="0" height="0" className=''> 
            <filter id="filter">
            <feTurbulence type="fractalNoise" baseFrequency=".01" numOctaves="10" />
            <feDisplacementMap in="SourceGraphic" scale="180" />
            </filter>
        </svg>  */}
        
        {/* Content */}
        <div className="relative z-20 ">
          <Routes>
            <Route path="/*" element={<Landing scrollerRef={scrollContainerRef} />} />
          </Routes>
        </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
