import React, { useState, useEffect, useRef } from 'react';
import flower1 from '../assets/flow.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const CommingSoon = ({scrollerRef, isArabic}) => {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2025-01-15T19:00:00');
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const flowerRef = useRef(null); // Reference for the flower image
  const secRef = useRef(null);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  
    // useEffect(() => {
    //   // GSAP animation for the flower image
    //   gsap.fromTo(
    //     flowerRef.current,
    //     { scale: 0 }, // Start from small
    //     {
    //       scale: 0.90, // End at full size
    //       duration: 1.5,
    //       ease: 'power3.out',
    //       scrollTrigger: {
    //         trigger: flowerRef.current,
    //         scroller: scrollerRef.current, // Specify the custom scroll container here
    //         start: '90% top',
    //         toggleActions: 'play none none none',
            
    //       },
    //     }
    //   );
    // }, [scrollerRef]);

  return (
    <div ref={secRef} className='bg-secondary bg-opacity-95 w-full flex flex-col items-center gap-10 relative z-30 pt-20 '>
      {/* Flower Image */}
      <div ref={flowerRef} className='absolute top-[-34%] sm:top-[-44%] md:top-[-35%] lg:top-[-50%] left-1/2 transform -translate-x-1/2 w-[50%] md:w-[25%]'>
        <img src={flower1} alt="Flower" className='object-contain' />
      </div>

      {/* Coming Soon Text */}
      <div className={`text-5xl  mt-14 ${isArabic?'noto':'cinzel-decorative-regular'}`}>{isArabic ? "العد التنازلي" : "Countdown"}</div>
      
      {/* Countdown Timer */}
      <div className={`flex ${isArabic?'flex-row-reverse':'flex-row'} justify-evenly w-full items-center gap-4 text-center text-4xl md:text-6xl font-bold mb-20`}>
        <div className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.days || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "أيام" : "Days"}</span>
        </div>
        <div className='text-4xl md:text-6xl'>:</div>
        <div className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.hours || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "ساعات" : "Hours"}</span>
        </div>
        <div className='text-4xl md:text-6xl'>:</div>
        <div className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.minutes || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "دقائق" : "Minutes"}</span>
        </div>
        <div className='text-4xl md:text-6xl'>:</div>
        <div className="flex flex-col">
          <span className='text-4xl md:text-6xl'>{timeLeft.seconds || 0}</span>
          <span className="text-sm md:text-lg">{isArabic ? "ثواني" : "Seconds"}</span>
        </div>
      </div>
    </div>
  );
};

export default CommingSoon;
