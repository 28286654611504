import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import gsap from 'gsap';
import Header from '../components/Header';
import Rsvp from '../components/rsvp/rsvp';
import LoveStory from '../components/LoveStory';
import CommingSoon from '../components/CommingSoon';
import AccountNum from '../components/AccountNum';
import flower1 from '../assets/flow.webp';
import TwoSideSection from '../components/TwoSidedSection';
import LanguageSelection from '../components/LanguageSelection';
import WeddingPlan from '../components/WeddingPlan';
import Navbar from '../components/Navbar';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import backgroundMusic from '../assets/final.mp3'
const Landing = ({ scrollerRef }) => {
  const [isArabic, setIsArabic] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(false);
  const [data, setData] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [error, setError] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('100');
  
  const location = useLocation(); // Hook to get current route location

  const audioRef = useRef(null);

  const [isMuted, setIsMuted] = useState(false); // State to track if the audio is muted

  // Toggle mute functionality
  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted; // Toggle the muted property
      setIsMuted(!isMuted); // Update the state
    }
  };
  
  // Scroll to top on route change
  useEffect(() => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTo(0, 0); // Scroll to top
    }
  }, [location, scrollerRef]); // Trigger on location change
  
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  }, []); // Trigger on location change

  const stRegis = {
    "5": {
      "name": "The St. Regis Hotel",
      "nameArabic": "فندق سانت ريجيس",
      "googleMaps": "https://maps.app.goo.gl/HMkTprqDPkhTjXgV7",
      "socialMedia": "https://www.marriott.com/en-us/hotels/caixr-the-st-regis-cairo/overview/",
      "mobileNumber": "+200225979000",
      "reserve": "https://www.marriott.com/event-reservations/reservation-link.mi?id=1725817510061&key=GRP&guestreslink2=true"
    }
  };

  const giftRegistry = {
    "100": {
      "name": "Gift Registry",
      "nameArabic": "سجل الهدايا",
      "vendorList": {
        "1": {
          "bankName": "The Saudi Investment Bank",
          "bankNameArabic": "البنك السعودي للإستثمار",
          "accountName": "Lama Osama Ragheb",
          "accountNameArabic": "لاما أسامة راغب",
          "IBAN": "SA8765000005555754094001",
          "accountNumber": "5555754094001",
          "SWIFT": "SIBCSARI"
        },
        "2": {
          "bankName": "The Saudi Investment Bank",
          "bankNameArabic": "البنك السعودي للإستثمار",
          "accountName": "Mohammed Ammar Alrefae",
          "accountNameArabic": "محمد عمار الرفاعي",
          "IBAN": "SA0365000000212E61369002",
          "accountNumber": "0212E61369002",
          "SWIFT": "SIBCSARI"
        }
      }
    }
  };
  
  
  useEffect(() => {
    if (data && data.vendors) {
      setUpdatedData({
        ...data,
        vendors: {
          ...data.vendors,
          "7": {
            ...data.vendors["7"],
            vendorList: {
              ...data.vendors["7"].vendorList,
              ...stRegis,
            }
          },
          "100": {
            ...giftRegistry["100"] // Add the Gift Registry tab
          }
        }
      });
    }
  }, [data]);
  

  useEffect(() => {
    setData({
      "registry": [
          {
              "bankName": "The Saudi Investment Bank ",
              "bankNameArabic": "\u0627\u0644\u0628\u0646\u0643 \u0627\u0644\u0633\u0639\u0648\u062f\u064a \u0644\u0644\u0625\u0633\u062a\u062b\u0645\u0627\u0631",
              "accountNumber": null,
              "IBAN": "SA8765000005555754094001",
              "beneficiaryName": "Lama Osama Ragheb",
              "beneficiaryNameArabic": "\u0644\u0645\u0627 \u0623\u0633\u0627\u0645\u0629 \u0631\u0627\u063a\u0628"
          },
          {
              "bankName": "The Saudi Investment Bank ",
              "bankNameArabic": "\u0627\u0644\u0628\u0646\u0643 \u0627\u0644\u0633\u0639\u0648\u062f\u064a \u0644\u0644\u0625\u0633\u062a\u062b\u0645\u0627\u0631",
              "accountNumber": null,
              "IBAN": "SA0365000000212E61369002",
              "beneficiaryName": "Mohammed Ammar Alrefae",
              "beneficiaryNameArabic": "\u0645\u062d\u0645\u062f \u0639\u0645\u0627\u0631 \u0627\u0644\u0631\u0641\u0627\u0639\u064a"
          }
      ],
      "vendors": {
          "1": {
              "name": "Make-Up Artists",
              "nameArabic": "\u0641\u0646\u0627\u0646\u064a\u0646 \u0627\u0644\u0645\u0643\u064a\u0627\u062c",
              "vendorList": {
                  "8": {
                      "name": "Sara Erian",
                      "nameArabic": "\u0633\u0627\u0631\u0629 \u0625\u0631\u064a\u0627\u0646",
                      "googleMaps": null,
                      "socialMedia": "https:\/\/www.instagram.com\/bysaraerian\/",
                      "mobileNumber": null
                  },
                  "9": {
                      "name": "Mirna Gamal",
                      "nameArabic": "\u0645\u064a\u0631\u0646\u0627 \u062c\u0645\u0627\u0644",
                      "googleMaps": null,
                      "socialMedia": "https:\/\/www.instagram.com\/mirnagamal.makeupartist\/",
                      "mobileNumber": null
                  },
                  "11": {
                      "name": "Norhan Amr",
                      "nameArabic": "\u0646\u0648\u0631\u0647\u0627\u0646 \u0639\u0645\u0631\u0648",
                      "googleMaps": null,
                      "socialMedia": "https:\/\/www.instagram.com\/by_norhanamr\/",
                      "mobileNumber": null
                  },
                  "34": {
                      "name": "Zeina ElNaggar",
                      "nameArabic": "\u0632\u064a\u0646\u0627 \u0627\u0644\u0646\u062c\u0627\u0631",
                      "googleMaps": null,
                      "socialMedia": "https:\/\/www.instagram.com\/zeinaelnaggar\/",
                      "mobileNumber": "+201006069356"
                  },
                  "35": {
                      "name": "Reham Khalifa",
                      "nameArabic": "\u0631\u064a\u0647\u0627\u0645 \u062e\u0644\u064a\u0641\u0629",
                      "googleMaps": null,
                      "socialMedia": "https:\/\/www.instagram.com\/rehamkhalifaa\/",
                      "mobileNumber": "+201141003316"
                  },
                  "37": {
                      "name": "Abdullah Alrefaeii",
                      "nameArabic": "\u0639\u0628\u062f\u0627\u0644\u0644\u0647 \u0627\u0644\u0631\u0641\u0627\u0639\u064a",
                      "googleMaps": null,
                      "socialMedia": "https:\/\/www.instagram.com\/abdallahelrefaeii\/",
                      "mobileNumber": "+201111812400"
                  },
                  "38": {
                      "name": "Chez Richard Beauty Salon",
                      "nameArabic": "\u0635\u0627\u0644\u0648\u0646 \u0631\u064a\u062a\u0634\u0627\u0631\u062f \u0648 \u0631\u0632\u0642",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/kEVESYonhaj5AuYB8",
                      "socialMedia": "https:\/\/www.facebook.com\/chezrichardsalon\/",
                      "mobileNumber": "+201270433337"
                  }
              }
          },
          "2": {
              "name": "Hair Stylists",
              "nameArabic": "\u0645\u0635\u0641\u0641\u064a \u0627\u0644\u0634\u0639\u0631",
              "vendorList": {
                  "2": {
                      "name": "Chez Richard Beauty Salon",
                      "nameArabic": "\u0635\u0627\u0644\u0648\u0646 \u0631\u064a\u062a\u0634\u0627\u0631\u062f \u0648 \u0631\u0632\u0642",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/kEVESYonhaj5AuYB8",
                      "socialMedia": "https:\/\/www.facebook.com\/chezrichardsalon\/",
                      "mobileNumber": "+201270433337"
                  },
                  "39": {
                      "name": "Richard Boustany",
                      "nameArabic": "\u0631\u064a\u062a\u0634\u0627\u0631\u062f \u0628\u0648\u0633\u062a\u0627\u0646\u064a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/G8HKjiXTr1RZJLhz6",
                      "socialMedia": "https:\/\/www.instagram.com\/richardelboustanylive",
                      "mobileNumber": "+201274120000"
                  },
                  "40": {
                      "name": "Mina Khamis",
                      "nameArabic": "\u0645\u064a\u0646\u0627 \u062e\u0645\u064a\u0633",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/FfBm1KcrAnvDJ4kk6",
                      "socialMedia": "https:\/\/www.instagram.com\/minakhamis",
                      "mobileNumber": "+201000052060"
                  },
                  "41": {
                      "name": "Kimi Safadi",
                      "nameArabic": "\u0643\u064a\u0645\u064a \u0633\u0627\u0641\u0627\u062f\u064a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/v13U9VQ8g1uLUbWk7",
                      "socialMedia": "https:\/\/www.instagram.com\/kimisafadi",
                      "mobileNumber": "+201004440044"
                  },
                  "42": {
                      "name": "Alfred & Mina",
                      "nameArabic": "\u0627\u0644\u0641\u0631\u064a\u062f \u0648\u0645\u064a\u0646\u0627",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/AnnQC4Ljto5F9yfH7",
                      "socialMedia": "https:\/\/instagram.com\/alfredandmina",
                      "mobileNumber": "+201020777779"
                  },
                  "43": {
                      "name": "Rizk Hair Studio",
                      "nameArabic": "\u0635\u0627\u0644\u0648\u0646 \u0631\u0632\u0642",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/FqrR76Dm6ZKcnjvg8",
                      "socialMedia": "https:\/\/www.instagram.com\/rizkhairstudio",
                      "mobileNumber": "+201003013258"
                  },
                  "44": {
                      "name": "Rabie Mourad Beauty Lounge ",
                      "nameArabic": "\u0635\u0627\u0644\u0648\u0646 \u062a\u062c\u0645\u064a\u0644 \u0631\u0628\u064a\u0639 \u0645\u0631\u0627\u062f",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/46W2Frp7yrhYGmtB8",
                      "socialMedia": "https:\/\/www.instagram.com\/rabiemrad",
                      "mobileNumber": "+200225391888"
                  }
              }
          },
          "3": {
              "name": "Transportation",
              "nameArabic": "\u0627\u0644\u0646\u0642\u0644",
              "vendorList": {
                  "93": {
                      "name": "Tropic Travel Services",
                      "nameArabic": "\u0634\u0631\u0643\u0629 \u062a\u0631\u0648\u0628\u064a\u0643 \u0644\u0644\u0633\u064a\u0627\u062d\u0629",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/TScrY4UHFiN7kfMc9",
                      "socialMedia": "https:\/\/www.facebook.com\/TropicTravelEgypt\/",
                      "mobileNumber": "+200233029153"
                  },
                  "94": {
                      "name": "Ayman Abu Mandour",
                      "nameArabic": "\u0623\u064a\u0645\u0646 \u0623\u0628\u0648 \u0645\u0646\u062f\u0648\u0631",
                      "googleMaps": null,
                      "socialMedia": null,
                      "mobileNumber": "+201002171908"
                  },
                  "95": {
                      "name": "Alfares Car Rentals",
                      "nameArabic": "\u0627\u0644\u0641\u0627\u0631\u0633 \u0644\u0627\u064a\u062c\u0627\u0631 \u0627\u0644\u0633\u064a\u0627\u0631\u0627\u062a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/ohQaKrEs9KFpqJ5MA",
                      "socialMedia": "https:\/\/www.facebook.com\/elfaresscar\/",
                      "mobileNumber": "+201006300261"
                  },
                  "96": {
                      "name": "Rashad",
                      "nameArabic": "\u0631\u0634\u0627\u062f",
                      "googleMaps": null,
                      "socialMedia": null,
                      "mobileNumber": "+201000339833"
                  },
                  "97": {
                      "name": "Four Wheels Car Rentals",
                      "nameArabic": "\u0634\u0631\u0643\u0629 \u0641\u0648\u0631 \u0648\u064a\u0644\u0632 \u0644\u0625\u064a\u062c\u0627\u0631 \u0627\u0644\u0633\u064a\u0627\u0631\u0627\u062a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/XwpedQRx8mSvKhJA7",
                      "socialMedia": "https:\/\/www.rentalcaregypt.com\/",
                      "mobileNumber": "+201222729837"
                  },
                  "98": {
                      "name": "Abdulrahman",
                      "nameArabic": "\u0639\u0628\u062f\u0627\u0644\u0631\u062d\u0645\u0646",
                      "googleMaps": null,
                      "socialMedia": null,
                      "mobileNumber": "+201002770282"
                  },
                  "99": {
                      "name": "M3 Limousine",
                      "nameArabic": "\u0634\u0631\u0643\u0629 \u0627\u0645 \u062b\u0631\u064a \u0644\u064a\u0645\u0648\u0632\u064a\u0646",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/9dioJ2kPKguNWipu5",
                      "socialMedia": "https:\/\/m3limousine.com\/",
                      "mobileNumber": "+201000062430"
                  },
                  "100": {
                      "name": "Smart Limo",
                      "nameArabic": "\u0634\u0631\u0643\u0629 \u0633\u0645\u0627\u0631\u062a \u0644\u064a\u0645\u0648",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/w5HvMmTrbtWFUdiX6",
                      "socialMedia": "https:\/\/www.smartlimo.com\/",
                      "mobileNumber": "+201112136580"
                  }
              }
          },
          "4": {
              "name": "Places To Go",
              "nameArabic": "\u0623\u0645\u0627\u0643\u0646 \u0644\u0644\u0632\u064a\u0627\u0631\u0647",
              "vendorList": {
                  "4": {
                      "name": "Waterway",
                      "nameArabic": "\u0627\u0644\u0648\u0627\u062a\u0631\u0648\u0627\u064a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/1tTRDWmML32moPxX6",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "18": {
                      "name": "The Lemon Tree & Co.",
                      "nameArabic": "\u0630\u0627 \u0644\u064a\u0645\u0648\u0646 \u062a\u0631\u064a \u0627\u0646\u062f \u0643\u0648",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/1LASTdKuqC9EgLq2A",
                      "socialMedia": "https:\/\/instagram.com\/thelemontreeandco",
                      "mobileNumber": "+201120999290"
                  },
                  "20": {
                      "name": "Sachi Restaurants",
                      "nameArabic": "\u0645\u0637\u0627\u0639\u0645 \u0633\u0627\u0634\u064a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/RjZZ1bUbm3BzhfZR7",
                      "socialMedia": "https:\/\/instagram.com\/sachirestaurants",
                      "mobileNumber": "+201280902028"
                  },
                  "21": {
                      "name": "Pier88 Group",
                      "nameArabic": "\u0645\u062c\u0645\u0648\u0639\u0629 \u0628\u064a\u064a\u0631 88",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/Gi2Q6UWn2YBaQSTD6",
                      "socialMedia": "https:\/\/instagram.com\/pier88group",
                      "mobileNumber": "+201080888876"
                  },
                  "25": {
                      "name": "Galambo Seafood Restaurant",
                      "nameArabic": "\u0645\u0637\u0639\u0645 \u062c\u0644\u0627\u0645\u0628\u0648 \u0644\u0644\u0623\u0633\u0645\u0627\u0643",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/xnoyKt6cUBMeL2mg9",
                      "socialMedia": "https:\/\/instagram.com\/galamboeg",
                      "mobileNumber": "+201212223330"
                  },
                  "27": {
                      "name": "White (The Waterway 2)",
                      "nameArabic": "\u0648\u0627\u062a\u0631\u0648\u0627\u064a 2",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/v9eqmHLfuecbjHxb9",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "28": {
                      "name": "Garden 8",
                      "nameArabic": "\u062c\u0627\u0631\u062f\u0646 8",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/hdMyyHdtHdXD5HZFA",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "29": {
                      "name": "Capital Business Park",
                      "nameArabic": "\u0643\u0627\u0628\u064a\u062a\u0627\u0644 \u0628\u064a\u0632\u0646\u0633 \u0628\u0627\u0631\u0643",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/jh3ns5enwQGpVKVq5",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "30": {
                      "name": "Park St",
                      "nameArabic": "\u0628\u0627\u0631\u0643 \u0633\u062a\u0631\u064a\u062a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/6w7SVBNcCYwqgEkF9",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "31": {
                      "name": "Arkan Mall",
                      "nameArabic": "\u0645\u0648\u0644 \u0623\u0631\u0643\u0627\u0646",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/zDo1H9NaAtGX8QHo8",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "32": {
                      "name": "5A Mall",
                      "nameArabic": "\u0645\u0648\u0644 \u0641\u0627\u064a\u0641 \u0627\u064a\u0647",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/toDvZ5NgH4aK2xb36",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "49": {
                      "name": "The Pyramids of Giza and The Sphinx",
                      "nameArabic": "\u0623\u0647\u0631\u0627\u0645\u0627\u062a \u0627\u0644\u062c\u064a\u0632\u0629 \u0648\u0623\u0628\u0648 \u0627\u0644\u0647\u0648\u0644",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/yqjvvsKZoBfo61h56",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "50": {
                      "name": "Khan Elkhalili Bazar",
                      "nameArabic": "\u0628\u0627\u0632\u0627\u0631 \u062e\u0627\u0646 \u0627\u0644\u062e\u0644\u064a\u0644\u064a",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/ZTyuugdysPXG7r2z8",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "51": {
                      "name": "The Grand Egyptian Museum",
                      "nameArabic": "\u0627\u0644\u0645\u062a\u062d\u0641 \u0627\u0644\u0645\u0635\u0631\u064a \u0627\u0644\u0643\u0628\u064a\u0631",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/jtv4yJ7xd6qzaqgz9",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "52": {
                      "name": "Salah Eldin Citadel",
                      "nameArabic": "\u0642\u0644\u0639\u0629 \u0635\u0644\u0627\u062d \u0627\u0644\u062f\u064a\u0646",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/sgboMpJ7aMAgUuVk6",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "53": {
                      "name": "Cairo Opera House",
                      "nameArabic": "\u062f\u0627\u0631 \u0627\u0644\u0623\u0648\u0628\u0631\u0627 \u0628\u0627\u0644\u0642\u0627\u0647\u0631\u0629",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/b3YXGi5gDB3MjeyWA",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "54": {
                      "name": "Om Kalthoum Museum",
                      "nameArabic": "\u0645\u062a\u062d\u0641 \u0623\u0645 \u0643\u0644\u062b\u0648\u0645",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/nxvv4qGFygFuhjMv5",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "55": {
                      "name": "Pharaonic Village",
                      "nameArabic": "\u0627\u0644\u0642\u0631\u064a\u0629 \u0627\u0644\u0641\u0631\u0639\u0648\u0646\u064a\u0629",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/CCbv8Rd4rb6A5d9r5",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "56": {
                      "name": "The London",
                      "nameArabic": "\u0645\u0637\u0639\u0645 \u0630\u0627 \u0644\u0646\u062f\u0646",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/5waVKTCsHfb2yCZm8",
                      "socialMedia": null,
                      "mobileNumber": null
                  },
                  "57": {
                      "name": "Abou El Sid Restaurant",
                      "nameArabic": "\u0645\u0637\u0639\u0645 \u0623\u0628\u0648 \u0627\u0644\u0633\u064a\u062f",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/QAad3tNQdKBa2gnH9",
                      "socialMedia": null,
                      "mobileNumber": null
                  }
              }
          },
          "7": {
              "name": "Accommodation",
              "nameArabic": "\u0627\u0644\u0625\u0642\u0627\u0645\u0629",
              "vendorList": {
                  "58": {
                      "name": "The Nile Ritz Cartlon",
                      "nameArabic": "\u0641\u0646\u062f\u0642 \u0627\u0644\u0646\u064a\u0644 \u0631\u064a\u062a\u0632 \u0643\u0627\u0631\u0644\u062a\u0648\u0646",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/H9f69qVTc8FuwYNr9",
                      "socialMedia": "https:\/\/www.ritzcarlton.com\/en\/hotels\/cairz-the-nile-ritz-carlton-cairo\/",
                      "mobileNumber": "+200225778899"
                  },
                  "59": {
                      "name": "Four Seasons Hotel Cairo at Nile Plaza",
                      "nameArabic": "\u0641\u0646\u062f\u0642 \u0627\u0644\u0641\u0648\u0631 \u0633\u064a\u0632\u0648\u0646\u0632 \u0627\u0644\u0642\u0627\u0647\u0631\u0629 \u0646\u0627\u064a\u0644 \u0628\u0644\u0627\u0632\u0627",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/rWz72kLEmE8pDW1F7",
                      "socialMedia": "https:\/\/www.fourseasons.com\/caironp\/",
                      "mobileNumber": "+200227917000"
                  },
                  "60": {
                      "name": "Conrad Cairo Hotel & Casino",
                      "nameArabic": "\u0641\u0646\u062f\u0642 \u0648\u0643\u0627\u0632\u064a\u0646\u0648 \u0643\u0648\u0646\u0631\u0627\u062f \u0627\u0644\u0642\u0627\u0647\u0631\u0629",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/C51gJTtrHceaice56",
                      "socialMedia": "https:\/\/www.hilton.com\/en\/hotels\/caicici-conrad-cairo\/",
                      "mobileNumber": "+200225808000"
                  },
                  "61": {
                      "name": "Cairo Marriott Hotel & Omar Khayyam Casino",
                      "nameArabic": "\u0641\u0646\u062f\u0642 \u0645\u0627\u0631\u064a\u0648\u062a \u0627\u0644\u0642\u0627\u0647\u0631\u0629 \u0648\u0643\u0627\u0632\u064a\u0646\u0648 \u0639\u0645\u0631 \u0627\u0644\u062e\u064a\u0627\u0645",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/5riRCGcJJW5fxRZA8",
                      "socialMedia": "https:\/\/www.marriott.com\/en-us\/hotels\/caieg-cairo-marriott-hotel-and-omar-khayyam-casino\/",
                      "mobileNumber": "+200227283000"
                  },
                  "62": {
                      "name": "Sheraton Cairo Hotel & Casino",
                      "nameArabic": "\u0641\u0646\u062f\u0642 \u0648 \u0643\u0627\u0632\u064a\u0646\u0648 \u0634\u064a\u0631\u0627\u062a\u0648\u0646 \u0627\u0644\u0642\u0627\u0647\u0631\u0629",
                      "googleMaps": "https:\/\/maps.app.goo.gl\/w6M3PEL2nt1xShuF9",
                      "socialMedia": "https:\/\/www.marriott.com\/en-us\/hotels\/caisi-sheraton-cairo-hotel-and-casino\/",
                      "mobileNumber": "+200233369800"
                  }
              }
          }
      }
  })
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch('https://mohammedandlama.com/getdata.php', {
    //       method: 'GET',
    //       headers: {
    //         'Authorization': 'ZPJTjkUuhg%',
    //         'Accept': 'application/json',
    //         'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
    //         'Content-Type': 'application/json',
    //       },
    //     });
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     const result = await response.json();
    //     setData(result);
    //   } catch (error) {
    //     setError(error.message);
    //   }
    // };
  
    // fetchData();
  }, []);

  const flowerRef = useRef(null);
  useEffect(() => {
    if (languageSelected) {
      gsap.fromTo(
        flowerRef.current,
        { scale: 0 },
        {
          scale: 0.90,
          duration: 1.5,
          ease: 'power3.out',
          scrollTrigger: {
            trigger: flowerRef.current,
            // scroller: scrollerRef.current,
            start: 'top 40%',
            toggleActions: 'play none none none',
          },
        }
      );
    }
  }, [scrollerRef, languageSelected]);

  const handleLanguageSelection = (lang) => {
    setIsArabic(lang === 'arabic');
    setLanguageSelected(true);
  };

  if (!languageSelected) {
    return <LanguageSelection setLanguage={handleLanguageSelection} />;
  }

  return (
    <div className={`flex flex-col items-center overflow-hidden text-text z-30 ${isArabic ? 'amiri tracking-tight	' : 'belleza-regular'}`}>
      {/* Audio Player */}
      <audio ref={audioRef} src={backgroundMusic} loop autoPlay>
          Your browser does not support the audio element.
        </audio>
      <Navbar isArabic={isArabic} setIsArabic={setIsArabic} toggleMute= {toggleMute} isMuted={isMuted} setActiveTabKey={setActiveTabKey}/>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header isArabic={isArabic} />
              <div id="rsvp" className="w-full relative flex flex-col justify-center items-center bg-primary bg-opacity-70 mt-10 pb-10 md:pb-20">
                <div ref={flowerRef} className="absolute top-[-120px] lg:top-[-200px] left-1/2 transform -translate-x-1/2 w-[50%] md:w-[25%]">
                  <img src={flower1} alt="Flower" className="object-contain" />
                </div>
                <div className="mt-24 md:mt-32 text-4xl cinzel-decorative-regular">{isArabic?'يرجى تأكيد الحضور':'RSVP'}</div>
                <Rsvp isArabic={isArabic} />
              </div>
            </>
          }
        />
        <Route path="/services" element={<TwoSideSection isArabic={isArabic} data={updatedData} activeTabKey={activeTabKey} />} />
        <Route path="/timeline" element={<LoveStory isArabic={isArabic} scrollerRef={scrollerRef} />} />
      </Routes>
      {/* <CommingSoon isArabic={isArabic} scrollerRef={scrollerRef} /> */}
      <div className='w-full h-auto bg-text text-center  text-white py-10'> Powered by <a target="_blank" rel="noopener noreferrer" href={isArabic?'https://flokisoft.com/products?lang=ar&id=1':'https://flokisoft.com/products?lang=en&id=1'} className='text-secondary font-bold underline' >FlokiEvents</a> </div>

    </div>
  );
};

export default Landing;
