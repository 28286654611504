import React from 'react';

const LanguageSelection = ({ setLanguage }) => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center bg-secondary bg-opacity-50 relaitve text-white">
      <h1 className="text-3xl mb-10">Select Language</h1>
      <div className="flex space-x-10">
        <button
          onClick={() => setLanguage('arabic')}
          className="px-6 py-3 bg-primary text-white rounded-md text-xl"
        >
          العربية
        </button>
        <button
          onClick={() => setLanguage('english')}
          className="px-6 py-3 bg-primary text-white rounded-md text-xl"
        >
          English
        </button>
      </div>
    </div>
  );
};

export default LanguageSelection;
